import React from "react";
import { Page404 } from "src/components/pageComponents";
import { graphql } from "gatsby";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import { SEO } from "src/components/commonComponents";
// Image is blurried on nginx page direction.
// Using html img tag for now.
const NotFoundPage = (props) => {
  const customRedirects = props.data?.redirects?.nodes
  const OGMarkup = getOGMarkup("404");

  return (
    <>
     <SEO
        pageUrl="/404"
        OGMarkup={OGMarkup}
      />
    <Page404 pageContext={props.pageContext} path={props.path} customRedirects={customRedirects} />
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
query getRedirectByTargetSite($targetSiteTid: String ) {
  redirects: allCustomRedirectCustomRedirect(
    filter: {field_target_site: {eq: $targetSiteTid}, status: {eq: true}}
  ) {
    nodes {
      from: field_from_url
      field_redirect_status_code
      field_target_site
      to: field_to_url
    }
  }
}
`;